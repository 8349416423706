<template>
    <div class="box">
        <!-- 左侧关闭 -->
        <div class="box-left">
            <div class="line">
                <a @click.stop="closeNav" class=" handel-header">
                    <local-svg class="icon hover" iconClass="navbtn" :class="{ 'acthaer': isClose }"></local-svg>
                </a>
            </div>
        </div>
        <!-- 右侧刷新与头像 -->
        <div class="line2">
            <div class="user avatar-handel">
                <img v-if="$store.state.userimage" class="user-avatar" :src="`${imgURL}${$store.state.userimage}`" alt=""
                    style="border-radius: 50%;">
                <img v-else class="user-avatar" src="../assets/image/user.jpg" alt="" style="border-radius: 50%;">
                <el-popover placement="bottom" title="" width="96" popper-class="showbox popover-element-change hover"
                    trigger="click">
                    <div class="showbox">
                        <div class="showbox-list" @click="toPersonal">个人中心</div>
                        <div @click="logout" class="showbox-list">退出登录</div>
                    </div>
                    <button class="button hover" slot="reference">{{ $store.state.username }} <local-svg
                            class="user-show hover" iconClass="admindown"></local-svg></button>
                </el-popover>
            </div>
            <div class="iconlist">
                <div class="">
                    <a @click="refsh" class="handel-header">
                        <local-svg class="list-image hover " iconClass="newload"></local-svg>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vm from '@/assets/js/closeNav'
export default {
    name: "Header",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            info: {
                avatar: require('../assets/image/user.png'),
                name: this.$store.state.username,
            },
            imgURL: process.env.VUE_APP_IMG_URL,
            isClose: false,
        };
    },
    mounted() {
    },
    methods: {
        // 个人中心
        toPersonal() {
            this.$router.push({
                path: '/terrace/personal'
            })
        },
        //刷新
        refsh() {
            this.$router.go(0);
        },
        //关闭左侧菜单栏
        closeNav() {
            if (this.isClose) {
                this.isClose = false
            } else {
                this.isClose = true
            }
            let type = this.isClose
            vm.$emit("closetype", type)
        },
        // 退出登录
        logout() {
            localStorage.removeItem('TOKEN')
            localStorage.removeItem('userimage')
            localStorage.removeItem('username')
            this.$router.push('/login')
        }
    },
}
</script>

<style lang="scss" scoped>
.showbox {
    padding: 8px 0;
}

</style>
<style lang="scss" scoped>
* {
    text-align: center;
    box-sizing: border-box;
}

.box {
    width: 100%;
    height: 60px;
    background: #fff;
    padding: 0;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 999;
    .box-left {
        display: flex;
        line-height: 60px;
        .line {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            width: 64px;
            border-right: 1px solid #F0F1F5;

            .handel .icon {
                color: #9A9EAA;
            }

            .icon {
                font-size: 20px;
            }
        }
    }
    .line2 {
        width: auto;
        height: 60px;
        display: flex;
        flex-direction: row-reverse;

        .user {
            width: auto;
            border-left: #F0F1F5 solid 1px;
            height: 100%;
            padding: 0 0 0 30px;
            text-align: left;
            display: flex;
            align-items: center;

            .user-avatar {
                width: 36px;
                height: 36px;
                margin-right: 14px;
            }

            .user-show {
                width: 10px;
                margin-left: 6px;
                margin-right: 30px;
            }

            .button {
                background: transparent;
                border: none;
                outline: none;
                font-size: 14px;
                line-height: 16px;
                white-space: nowrap // width: auto;
                    // display: flex;
                    // flex-wrap: nowrap;
            }
        }

        .avatar-handel {
            button {
                color: #B0B1B9;
            }

            .svg-icon {
                fill: #B0B1B9;
            }
        }

        .avatar-handel:hover {
            button {
                color: #1D78FE !important;
            }

            .svg-icon {
                fill: #1D78FE !important;
            }
        }
        .iconlist {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;


            .list-image {
                width: 20px;
                height: 18px;
                margin-right: 30px;
            }
        }
    }
}

.showbox {
    .showbox-list {
        width: 100%;
        height: 36px;
        color: #9CA3B6;
        line-height: 36px;
        font-size: 14px;
    }
}

.acthaer {
    transform: rotate(180deg);
}

@import "@/assets/scss/flex.scss"</style>
