import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import Fragment from 'vue-fragment'
import axios from 'axios'
import { message } from './utils/message';

import 'element-ui/lib/theme-chalk/index.css';
import '@/icons'
import Directives from "./utils/index"
Vue.use(Directives);
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.axios = axios
Vue.use(Fragment.Plugin);
Vue.prototype.$bus = new Vue()
Vue.prototype.$message = message;
Vue.prototype.$BASE_URL = 'https://tpback.lianghang.cc/'
ElementUI.Dialog.props.closeOnClickModal.default = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
