<template>
    <div class="box main-navbar">
        <el-menu class="el-menu-vertical-demo" :collapse="isCollapse" :unique-opened="true" background-color="#1D78FE" text-color="#CEE2FF" active-text-color="#fff"
            :collapse-transition="false" :default-active="currentMenu">
            <div class="logo">
                <img v-if="!isCollapse" src="../assets/image/Frame.png" alt="">
                <img v-else src="../assets/image/closelogo.png" alt="">
            </div>
            <!-- 左侧系统名称 -->
            <!-- <div v-if="!isCollapse"
                style="text-align: center;font-size: 12px;color: #99C2FF;margin-top: -10px;margin-bottom: 10px;">
                <span><span style="margin-right: 10px;color: #5097FF;">——</span>{{ version }}</span><span
                    style="margin-left: 10px;color: #5097FF;">——</span>
            </div> -->
            <!-- 菜单栏主体 -->
            <div>
                <fragment v-for="(item, index) in list" :key="index">
                    <el-submenu v-if="item.page && item.has_child == 1" :index="item.path">
                        <template slot="title">
                            <local-svg :iconClass="item.icon + ''" class="icon"></local-svg>
                            <span v-if="!isCollapse">{{ item.name }}</span>
                        </template>
                        <el-menu-item-group v-for="(tab, sonIndex) in item.page" :key="sonIndex">
                            <el-menu-item :index="tab.route" @click="changPage(tab)" class="child-nav">{{ tab.name
                            }}</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-menu-item class="title-first" v-else :index="item.route" @click="changPage(item)">
                        <local-svg :iconClass="item.icon + ''" class="icon"></local-svg>
                        <span slot="title">{{ item.name }}</span>
                    </el-menu-item>
                </fragment>
            </div>
        </el-menu>
    </div>
</template>

<script>
import vm from '@/assets/js/closeNav'  //兄弟组件方法 头部缩小左侧菜单栏
export default {
    name: "NavBarLeft",
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {
            isCollapse: false,
            currentMenu: 'terrace/personal', //选中的菜单名字
            version: '',
        };
    },
    mounted() {
        this.getType() //监听关闭方法
        this.currentMenu = this.$route.path
    },
    watch: {
        //切换路由时 左侧菜单栏选择菜单跟着变化
        $route(e) {
            this.$store.commit('setRoute', e.path)
            this.currentMenu = e.path
        },
    },
    methods: {
        //监听左侧菜单栏关闭打开
        getType() {
            vm.$on('closetype', value => {
                this.isCollapse = value
            })
        },
        // 切换页面
        changPage(data) {
            console.log(data)
            this.$router.push({ path: data.route })
            vm.$emit("closeobj", data)
        },
    }
}
</script>

<style lang="scss">
.box {
    height: 100vh;
    min-height: 100%;

    .el-menu-vertical-demo {
        box-sizing: border-box;
        height: auto;
        min-height: 100%;
        // height: 100%;
        padding: 35px 10px 0;

        .logo {
            width: 100%;
            text-align: center;

            img {
                height: 80px;
                width: 80px;
                margin-bottom: 30px;
            }
        }

        .icon {
            width: 24px;
            height: 24px;
            margin-right: 14px;
        }
    }

    .el-menu-item.is-active {
        background-color: rgba(0, 0, 0, 0.2) !important;
        color: #fff;
    }

    .el-menu-item:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }

    .el-submenu.is-active {
        background-color: rgba(0, 0, 0, 0.2) !important;
    }

    .el-submenu:hover {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }

    .el-menu--popup-right-start {
        background-color: white !important;
    }

    .el-menu-vertical-demo {
        min-width: 80px !important;
    }

    .el-menu--collapse {
        width: 80px !important;
        text-align: center;
        color: #C2D3FF !important;

        .el-menu-item .is-active {
            background: rgba(0, 0, 0, 0.2) !important;
        }

        .logo img {
            width: 48px;
            height: 48px;
        }

        .is-active {
            color: white !important;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }

        .el-tooltip {
            display: flex !important;
            justify-content: center;
            align-items: center;
            padding: 0 14px 0 14px !important;

            .icon {
                width: 28px !important;
                height: 28px !important;
                margin-right: 0;
            }
        }

        .el-submenu__title {
            display: flex !important;
            justify-content: center;
            align-items: center;
            padding: 0 14px 0 14px;
            padding-left: 14px !important;

            .icon {
                width: 28px !important;
                height: 28px !important;
                margin-right: 0;
            }
        }
    }

    .el-submenu__icon-arrow {
        transform: rotate(-90deg);
    }

    .el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow {
        transform: rotateZ(0);
    }

    .el-icon-arrow-right:before {
        display: none;
    }

    .title-first {
        height: 56px !important;
        line-height: 56px !important;
    }

    .title-first:hover {
        color: #fff !important;
    }

    .child-nav:hover {
        color: #fff !important;
    }

    .el-submenu__title {
        height: 56px !important;
        line-height: 56px !important;
    }
}

.el-menu--vertical {
    .el-menu--popup {
        background-color: white !important;
    }

    .el-menu-item {
        background-color: white !important;
        color: #aaa !important;
    }

}

.el-select-dropdown {
    box-sizing: border-box;
    padding: 12px 0;
}

.el-menu-item:hover {
    // border-radius: 4px !important;
}

.el-submenu .el-menu-item {
    text-align: left;
    padding: 0 55px !important;
    line-height: 40px !important;
    height: 40px !important;
}

.el-menu-item-group__title {
    display: none !important;
}

.el-submenu .el-menu {
    box-sizing: border-box;
}

.title-first {
    font-weight: bold;
    display: flex !important;
    align-items: center !important;
}

.el-submenu.is-active {
    border-radius: 4px !important;
}

.el-submenu.is-active .el-submenu__title {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px !important;
}

.el-submenu.is-active:hover {
    border-radius: 4px !important;
}

.el-submenu__title {
    font-weight: bold;
    display: flex !important;
    align-items: center !important;
    border-radius: 4px !important;
    border-radius: 0 !important;
}

.el-submenu__title:hover {
    border-radius: 4px !important;
    background-color: rgba(0, 0, 0, 0.1);
}

.navbar .is-opened {
    background-color: transparent !important;
}

.box .el-menu-item.is-active {
    border-radius: 4px;
}

// 收起icon大小
.el-menu--collapse .el-tooltip .icon {
    width: 24px !important;
    height: 24px !important;
}

.el-menu--popup {
    padding: 5px 5px !important;
    min-width: auto !important;
}

.el-dropdown-menu__item,
.el-menu-item {
    font-size: 14px;
    padding: 0 15px;
    cursor: pointer;
    // border-radius: 4px;
}

.el-menu-item {
    height: 40px !important;
    line-height: 40px !important;
}

.el-menu-item:hover {
    background-color: #EDF3FE !important;
    color: #6B6D77 !important;
}

.el-submenu__title i {
    color: #fff !important;
}
</style>
