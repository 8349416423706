<template>
    <div class="mainbox">
        <div class="loginmain">

            <div class="logincontent">
                <div class="mainleft">
                    <div class="loginfont">
                        <img src="@/assets/image/loginfont.png" alt="">
                    </div>
                    <div class="zuhe">
                        <div class="B"><img src="@/assets/image/login-all.png" alt=""></div>
                        <!-- <div class="liu"><img src="@/assets/image/loginlc.png" alt=""></div> -->
                        <div class="yun"><img src="@/assets/image/loginyun.png" alt=""></div>
                        <div class="btouying"><img src="@/assets/image/btouying.png" alt=""></div>
                        <!-- <img src="../../assets/image/loginzu.png" alt=""> -->
                    </div>
                </div>
                <div class="mainright">
                    <div v-if="loginforget === 1" class="logintype">
                        <div class="typetop">
                            <div style="margin-bottom: 16px;"><img src="@/assets/image/logo.png" alt=""></div>
                            <div class="pe">
                                <div @click="tpchange(0)" class="password tp" :class="{'lantp':typenum === 0,'lantp-hover':typenum === 1}">密码登录</div>
                                <div @click="tpchange(1)" class="ercode tp"  :class="{'lantp':typenum === 1,'lantp-hover':typenum === 0}">企微登录</div>
                            </div>

                        </div>
                        <div class="typebottom">
                            <div v-show="typenum === 0" class="bottomone">
                                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
                                    class="demo-ruleForm">
                                    <el-form-item prop="phone">
                                        <el-input @keyup.enter.native="login" placeholder="手机号/邮箱" v-model="ruleForm.phone">
                                            <template #prepend>
                                                <local-svg iconClass="phone"></local-svg>
                                            </template>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item prop="password">
                                        <el-input @keyup.enter.native="login" placeholder="请输入密码"
                                            v-model="ruleForm.password" :type="texttype">
                                            <template #prepend>
                                                <local-svg iconClass="lock"></local-svg>
                                            </template>
                                            <template #append>
                                                <div @click="eye" class="eye">
                                                    <local-svg v-if="eyetype === true" iconClass="eye_close"></local-svg>
                                                    <local-svg v-if="eyetype === false" iconClass="eye_open"></local-svg>
                                                </div>
                                            </template>
                                        </el-input>
                                    </el-form-item>
                                </el-form>
                                <div class="jw">
                                    <div><el-checkbox v-model="reminder">记住账号</el-checkbox></div>
                                    <div @click="forget" class="wjmm handel">忘记密码?</div>
                                </div>
                                <div @click="login" v-show="ruleForm.phone && ruleForm.password" class="loginbuttony">登 录
                                </div>
                                <div v-show="!ruleForm.phone || !ruleForm.password" class="loginbutton" ref="aaa">登 录</div>

                            </div>
                            <div v-show="typenum === 1" class="bottomtwo">
                                <div style="width: 200px;
                                height: 200px;
                                display: flex;
                                justify-content: center;
                                align-items: flex-end;
                                color: #B0B1B9;">功能开发中...</div>
                            </div>
                        </div>

                    </div>
                    <div v-if="loginforget === 2" class="forget">
                        <div class="ffont">
                            <span class="title">忘记密码</span>
                            <span class="back hover handel" @click="loginforget = 1">返回登陆>></span>
                        </div>
                        <div>
                            <el-form :model="forform" :rules="forrule" ref="ruleFormfor" label-width="100px"
                                class="demo-ruleForm">
                                <el-form-item prop="phone">
                                    <el-input placeholder="手机号码" v-model="forform.phone">
                                        <template #prepend>
                                            <local-svg iconClass="phone"></local-svg>
                                        </template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="cord">
                                    <el-input placeholder="请输入验证码" v-model="forform.cord">
                                        <template #prepend>
                                            <local-svg iconClass="safe"></local-svg>
                                        </template>
                                        <template #append>
                                            <div v-if="ganCodeVis" @click="getcode" class="yanzheng handel">
                                                发送验证码
                                            </div>
                                            <div v-else class="yanzheng">
                                                <span style="color: #2C57FF;">{{ CodeTime }}s</span>后重新发送
                                            </div>
                                        </template>
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="password">
                                    <el-input placeholder="密码不小于6位且不大于12位" v-model="forform.password">
                                        <template #prepend>
                                            <local-svg iconClass="lock"></local-svg>
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div @click="confirmbut('ruleFormfor')" class="querenbut">确认修改
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { Login, code, reset } from '../../api/login'
import {routesData} from '@/utils/permission'
const Base64 = require('js-base64').Base64
export default {
    data() {
        return {
            CodeTime: 60,
            ganCodeVis: true,
            loginforget: 1,
            reminder: false,
            texttype: 'password',
            eyetype: true,
            typenum: 0,
            ruleForm: {
                phone: '',
                password: ''
            },
            forform: {
                phone: '',
                cord: '',
                password: ''
            },
            forrule: {
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'change' },
                    {
                        required: true,
                        pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur',
                    },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'change' },

                ],
                cord: [
                    { required: true, message: '请输入验证码', trigger: 'change' },

                ]
            },

            rules: {
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'change' },
                    {
                        required: true,
                        pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur',
                    },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'change' },

                ]
            }

        }
    },
    methods: {
        tpchange(num) {
            this.typenum = num
            if (num === 1) {
                this.$nextTick(() => {
                    window.WwLogin({
                        "id": "wx_login",
                        "appid": "ww6762914172a75da1",
                        "agentid": "1000021",
                        "redirect_uri": "http://test.tjlhyg.com/login/OA",     //回调页面
                        "state": "sip_Wxlogin",
                        "href": `data:text/css;base64,${Base64.encode(
                            `.impowerBox .qrcode {width: 200px;}
                            .impowerBox .title {display: none;}
                            .impowerBox .info {width: 200px;}
                            .impowerBox .status {text-align: center;}
                            .impowerBox .status_icon {display:none;}
                            .wrp_code_rl_btn {border:none;background-color:#2C57FF !important;color:#FFFFFF !important}`
                            // `.impowerBox .title {display: none;}`
                        )}`,
                    });
                })
            } else {
                if (this.ruleForm.phone && this.ruleForm.password) {
                    let re = document.getElementsByClassName('loginbutton')
                    let a = re.getElementByTagName('iframe')
                    a.remove()

                } else {
                    let res = document.getElementsByClassName('loginbuttony')
                    let aa = res.getElementByTagName('iframe')
                    aa.remove()
                }

                // if (!this.ruleForm.phone || !this.ruleForm.password) {

                // }





            }
        },
        eye() {
            this.eyetype = !this.eyetype
            this.eyetype === true ? this.texttype = 'password' : this.texttype = 'text'
        },

        async login() {
            // console.log(111);
            let data = {
                mobile: this.ruleForm.phone,
                password: this.ruleForm.password
            }
            let res = await Login(data)
            // console.log(res);
            if (res.code === 200) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                })
                if (this.reminder === true) {
                    window.localStorage.setItem('phone', this.ruleForm.phone)
                    let password = Base64.encode(this.ruleForm.password)
                    window.localStorage.setItem('password', password)

                }
                else {
                    window.localStorage.setItem('phone', '')
                    window.localStorage.setItem('password', '')
                }
                // 主要方法  存token 设置权限  本地保存用户名与图片 生成按钮权限 其他方法可删除
                window.localStorage.setItem('token', res.data.token)
                this.$store.commit('setUserPower', res.data.permission)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserImage', res.data.image)
                routesData(this.$store.state.userpower)
                this.setBtnPower(res.data.permission)
                await this.$router.push('/terrace/member')
            } else {
                this.$message({
                    message: res.msg,
                    type: 'error'
                })
            }

        },
        //生成按钮权限
        setBtnPower(array){
            let arr = []
            for(var a = 0;a<array.length;a++){
                let item = array[a]
                for(var b=0;b<item.page.length;b++){
                    let initem = item.page[b]
                    if(initem.button){
                        for(var c = 0;c<initem.button.length;c++){
                            let btnname = item.has_child==1?initem.name+'-'+initem.button[c]:item.name+'-'+initem.button[c]
                            arr.push(btnname)
                        }
                    }
                    
                }
            }
            this.$store.commit('setUserBtn',arr)
        },
        forget() {
            this.loginforget = 2
        },
        async getcode() {
            let res = await code(this.forform.phone)
            if (res.code === 200) {
                this.$message({
                    message: res.msg,
                    type: 'success'
                })
                this.ganCodeVis = false
                setInterval(() => {
                    if (this.CodeTime === 0) {
                        this.ganCodeVis = true

                    } else {
                        this.CodeTime = this.CodeTime - 1

                    }
                }, 1000)
            } else {
                this.$message({
                    message: res.msg,
                    type: 'error'
                })
            }
            // console.log(res);
        },
        confirmbut(ruleFormfor) {
            this.$refs[ruleFormfor].validate(async (valid) => {
                if (valid) {
                    let data = {
                        mobile: this.forform.phone,
                        code: this.forform.cord,
                        password: this.forform.password

                    }
                    let res = await reset(data)
                    if (res.code === 200) {
                        this.$message.success(res.msg)
                        this.loginforget = 1


                    }
                    else {
                        this.$message.error(res.msg)

                    }
                    // console.log(res);
                }
            })

        }



    },
    created() {
        let mobile = window.localStorage.getItem('phone')
        let password = localStorage.getItem('password')?Base64.decode(window.localStorage.getItem('password')):''
        if (mobile) {
            this.ruleForm.phone = mobile
            this.ruleForm.password = password
            this.ruleForm.reminder = true
        }
    }

}
</script>
<style lang="scss" scoped>
@keyframes updown {
    0% {
        transform: translateY(0);

    }
    25% {
        transform: translateY(-12.5px);

    }
    50% {
        transform: translateY(-25px);

    }
    75% {
        transform: translateY(-12.5px);

    }
    100% {
        transform: translateY(0);

    }
}

@keyframes leftright {
    // 0% {
    //     transform: translateX(Opx);

    // }

    // 25% {
    //     transform: translateX(-30px);

    // }

    // 50% {
    //     transform: translateX(-60px);

    // }

    // 75% {
    //     transform: translateX(-30px);

    // }

    // 100% {
    //     transform: translateX(Opx);

    // }
    0% {
        transform: translateY(-30px);

    }
    
    25% {
        transform: translateY(-20px);

    }

    50% {
        transform: translateY(-10px);

    }

    75% {
        transform: translateY(-20px);

    }

    100% {
        transform: translateY(-30px);

    }
}

@keyframes transp {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.3;
    }

    75% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;

    }
}

.mainbox {
    position: absolute;
    min-width: 100%;
    height: 100%;
    background: url('@/assets/image/loginbg.png') no-repeat;
    background-size: 100% 100%;

    .loginmain {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .logincontent {
            display: flex;
            margin-right: 150px;

            .mainleft {

                display: flex;
                flex-direction: column;
                align-items: center;
                pointer-events: none;

                .zuhe {
                    margin-top: 108px;
                    width: 679px;
                    height: 353px;
                    // background-image: url('@/assets/image/login-all.png');
                    position: relative;

                    .B {
                        animation: updown 5s infinite linear;
                        position: absolute;
                        left: 0;
                        top: -35px;
                        z-index: 11;
                        scale: 0.98;
                    }

                    .liu {
                        position: absolute;
                        left: 233px;
                        top: 152px;
                    }

                    .yun {
                        animation: leftright 8s infinite linear;

                        position: absolute;
                        left: 498px;
                        top: -3px;
                    }

                    .btouying {
                        animation: transp 5s infinite linear;
                        position: absolute;
                        left: 17px;
                        top: 126px;
                        z-index: 10;
                    }
                }
            }

            .mainright {

                width: 400px;
                height: 540px;
                background: #FFFFFF;
                box-shadow: 0px 10px 40px 0px rgba(91, 131, 235, 0.1);
                border-radius: 10px 10px 10px 10px;
                opacity: 1;
                margin-left: 275px;

                .logintype {
                    width: 100%;
                    height: 100%;
                    padding: 50px 40px 72px 40px;
                    box-sizing: border-box;

                    .typetop {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 25px;

                        .pe {
                            width: 100%;
                            display: flex;



                            .password {
                                cursor: pointer;
                                height: 50px;
                                line-height: 50px;

                                width: 50%;
                                font-size: 16px;
                                font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                                font-weight: 400;
                                color: #96A3B4;
                                border-bottom: 1px solid #EBEDF6;
                            }

                            .ercode {
                                cursor: pointer;
                                text-align: end;
                                height: 50px;
                                line-height: 50px;

                                width: 50%;
                                font-size: 16px;
                                font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                                font-weight: 400;
                                color: #96A3B4;
                                border-bottom: 1px solid #EBEDF6;
                            }

                            .lantp {
                                color: #2C57FF;
                                border-bottom: 2px solid #2C57FF;
                            }
                            .lantp-hover:hover{
                                color: #2C57FF !important;
                            }
                        }
                    }

                    .typebottom {
                        .eye {
                            cursor: pointer;
                        }

                        .eye:hover {
                            .svg-icon {
                                fill: #2C57FF !important;
                            }
                        }

                        .jw {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .wjmm {

                                font-size: 14px;
                                font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                                font-weight: 400;
                                color: #475B93;


                            }

                            .wjmm:hover {
                                cursor: pointer;
                            }
                        }

                        .loginbutton {
                            width: 320px;
                            height: 48px;
                            background: #B8C6FF;
                            box-shadow: 0px 2px 16px 0px #CAD5FE;
                            border-radius: 4px 4px 4px 4px;
                            color: #fff;
                            // cursor: pointer;
                            user-select: none;
                            text-align: center;
                            line-height: 48px;
                            opacity: 1;
                            margin-top: 63px;
                        }

                        .loginbuttony {
                            width: 320px;
                            height: 48px;
                            background: #2C57FF;
                            box-shadow: 0px 2px 16px 0px #CAD5FE;
                            border-radius: 4px 4px 4px 4px;
                            opacity: 1;
                            color: #fff;
                            cursor: pointer;
                            user-select: none;
                            text-align: center;
                            line-height: 48px;
                            opacity: 1;
                            margin-top: 63px;
                        }

                        .bottomtwo {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                    }
                }

                .forget {
                    padding: 70px 40px 10px 40px;

                    .ffont {

                        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                        font-weight: 400;
                        margin-bottom: 40px;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        .title{
                            color: #4C526D;
                            font-size: 18px;
                        }
                        .back{
                            color: #96A3B4;
                            font-size: 14px;
                        }
                    }

                    .yanzheng {

                        font-size: 14px;
                        font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                        font-weight: 400;
                        color: #475B93;
                        cursor: pointer
                    }

                    .querenbut {
                        width: 320px;
                        height: 48px;
                        background: #2C57FF;
                        box-shadow: 0px 2px 16px 0px #CAD5FE;
                        border-radius: 4px 4px 4px 4px;
                        opacity: 1;
                        color: #fff;
                        text-align: center;
                        line-height: 48px;
                        margin-top: 60px;
                        cursor: pointer
                    }
                }
            }
        }

        // background-color: red;

    }
}

:deep(.el-form-item__content) {
    margin-left: 0px !important;
}

:deep(.el-input__inner) {
    background-color: #F6F6FA;
    border: none !important;
}

:deep(.el-input-group__prepend) {
    border: none !important;
    background-color: #F6F6FA !important;
    padding-right: 0 !important;
}

:deep(.el-input-group__append) {
    border: none !important;
}




.disnon {
    display: none;
}</style>