import request from '../utils/request'

//外部跳转登录
export function jumplogin(data){
    return request({
        url:'/check',
        method:'post',
        data
    })
}
export function Login(data) {
    return request({
        url: '/back/v1/login/login',
        method: 'post',
        data
    })
}
// 重置密码
export function reset(data) {
    return request({
        url: '/back/v1/login/reset',
        method: 'post',
        data
    })
}
// 获取验证码
export function code(phone) {
    return request({
        url: '/back/v1/login/code',
        method: 'post',
        data: {
            mobile: phone
        }
    })
}