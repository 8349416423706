import router from "../router/index"
import store from "../store/index"
import ro from "element-ui/src/locale/lang/ro";

export function routesData(data) {
// 重新加载一次 VueRouter
    router.reloadRouter()
    let array = []
    data.forEach(item => {
        if(item.has_child==1){
            item.page.forEach(i => {
            // 组装路由配置
                const temp = {
                    name: i.comp,
                    path: i.route,
                    component: () => import(`../views/${i.comp_addr}.vue`)
                }
                console.log(`../views/${i.comp_addr}.vue`)
                // 动态添加为 index 的子路由
                router.addRoute("home", temp)
                array.push(temp)
            })
        }else{
            const temp = {
                name: item.comp,
                path: item.route,
                component: () => import(`../views/${item.comp_addr}.vue`)
            }
            router.addRoute("home", temp)
            // array.push(temp)
        }
    })
    // 动态添加一个 404 页面
    // router.addRoute({
    //     path: "/404",
    //     component: () => import("../views/404.vue")
    // })
}
if(store.state.userpower.length>1){
    console.log(1)
}
export default {
    inserted(el,bindling){
    	//bindling.value为指令的绑定值
        let perVal = bindling.value;
        if(bindling.value){
        //假设某用户对某模块只有添加和删除的权限
    	//这个权限信息(即pers)应该是不同用户登录时从后台拿到的对应的信息
            let pers=store.state.userbtn;
            //hasPer为true为有权限
    		//hasPer为false为无权限
            let hasPer = pers.some(item=>{
                return item == perVal
            });
             //没有权限就先隐藏此元素吧
            if(!hasPer){
                el.style.display="none"
            }
        }
    }
}
