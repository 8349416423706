import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Login from '../views/Login/login.vue'
import unfind from '../views/404.vue'
import { routesData } from '@/utils/permission'
import store from "../store/index"
Vue.use(VueRouter)

export const routes = [
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '/',
		name: 'home',
		component: Home,
		redirect: "/terrace/depart",
		children: [
			{
			  path: '/terrace/depart',
			  name: 'depart',
			  component: () => import(`../views/terrace/depart-terrace.vue`),
			},
			{
			  path: '/terrace/member',
			  name: 'member',
			  component: () => import(`../views/terrace/member-terrace.vue`),
			},
			{
			  path: '/terrace/role',
			  name: 'role',
			  component: () => import(`../views/terrace/role-terrace.vue`),
			},
			{
			  path: '/terrace/power',
			  name: 'power',
			  component: () => import(`../views/terrace/power-terrace.vue`),
			},
			{
			  path: '/terrace/personal',
			  name: 'personal',
			  component: () => import(`../views/terrace/personal-terrace.vue`),
			},
		]
	},
	{
		path: '/404',
		name: '404',
		component: unfind
	},

]
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error)
}



const router = new VueRouter({
	routes
})
router.reloadRouter = function () {
	router.matcher = new VueRouter({
		routes
	}).matcher
}
let hasRoles = true
router.beforeEach(async (to, from, next) => {
	next();
	// if(to.matched.length === 0 && hasRoles == false){
	// 	next('/404')
	// }else{
	// 	// 要去登录界面,直接放行
	// 	if (to.path === '/login') {
	// 		next();
	// 	} else {
	// 		const token = localStorage.getItem('token');
	// 		if (!token) {
	// 			next('/login')
	// 		} else {
	// 			if(hasRoles){
	// 				await routesData(store.state.userpower)
	// 				hasRoles = false
	// 				next({...to,replace: true})
	// 			}else{
	// 				next();
	// 			}
	// 			next();
	// 		}
	// 	}
	// }
	
})

export default router
